import { useState } from "react";
import Logo from "../assets/logo.png";
import BannerImg from "../assets/man.png";
import { LoginUser } from "../Api/Admin";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [clicked, setclicked] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const loginbtn = async () => {
    setclicked(true);
    const newerrors = {};
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialchar = /[!@#$%^&amp;*()_+]/g;

    if (!inputs.email) {
      newerrors.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email)
    ) {
      newerrors.email = "Invalid email address";
    } else if (!inputs.password) {
      newerrors.password = "Password Required";
    } else if (!inputs.password.match(lowerCaseLetters)) {
      newerrors.password = "Password must have lower case letters";
    } else if (!inputs.password.match(upperCaseLetters)) {
      newerrors.password = "Password must have upper case letters";
    } else if (!inputs.password.match(numbers)) {
      newerrors.password = "Password must have number";
    } else if (!inputs.password.match(specialchar)) {
      newerrors.password = "Password must have special characters";
    } else if (inputs.password.length < 6) {
      newerrors.password = "Must be 6 characters";
    }
    seterrorlist(newerrors);
    setclicked(false);
    if (Object.keys(newerrors).length === 0) {
      setclicked(true);
      var loginuser = await LoginUser(inputs);
      if (loginuser.message === "SUCCESS") {
        sessionStorage.setItem("user_id", loginuser.data.userdata[0].id);
        toast.success("Welcome back...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(loginuser.message, {
          autoClose: 2000,
          transition: Slide,
        });
        setclicked(false);
      }
    }
  };

  return (
    <div className="grid grid-cols-2">
      <div className="logindiv bg-gradient-to-r from-cyan-500 to-blue-500">
        <div className="h-full flex flex-col items-center justify-center">
          <h2 className="text-white text-xl">"Your money., Your way"</h2>
          <h3 className="text-white text-3xl">Transfer with confidence</h3>
          <img src={BannerImg} alt="Banner Mespay" className="h-96" />
        </div>
      </div>
      <div className="px-16 flex items-center justify-center">
        <div className="w-80 flex flex-col">
          <img
            src={Logo}
            alt="Mespay Logo"
            className="my-4 h-[10rem] object-contain"
          />
          <h1 className="text-xl font-bold my-4">
            Login to your admin account
          </h1>
          <div className="flex flex-col">
            <label className="text-gray-500">Email</label>
            <input
              type="text"
              className="w-80 border font-light text-sm my-2 px-4 py-2 rounded"
              placeholder="Enter your Email"
              name="email"
              onChange={handleChange}
            />
            {errorlist !== null ? (
              <div className="text-red-500">{errorlist.email}</div>
            ) : null}
            <label className="text-gray-500">Password</label>
            <input
              type="password"
              className="w-80 border my-2 font-light text-sm px-4 py-2 rounded"
              placeholder="Enter your password"
              name="password"
              onChange={handleChange}
            />
            {errorlist !== null ? (
              <div className="text-red-500">{errorlist.password}</div>
            ) : null}
            {clicked === false ? (
              <button
                className="bg-[#007DFA] my-4 h-10 rounded text-white"
                onClick={loginbtn}
              >
                Login
              </button>
            ) : (
              <button className="bg-[#007DFA] my-4 h-10 rounded text-white">
                Login
              </button>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
